
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as adminasuserGzijq1pieyMeta } from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/adminasuser.vue?macro=true";
import { default as indexjU56J6KjWBMeta } from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/index.vue?macro=true";
import { default as mes_45chargeshKkUYAen1mMeta } from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mes-charges.vue?macro=true";
import { default as mes_45consommationsw3UjNCAB1qMeta } from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mes-consommations.vue?macro=true";
import { default as mes_45documentsL86RItrN4gMeta } from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mes-documents.vue?macro=true";
import { default as mes_45equipementsDv3l3vr584Meta } from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mes-equipements.vue?macro=true";
import { default as mon_45profilR97EcscWVxMeta } from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mon-profil.vue?macro=true";
import { default as mon_45tableau_45de_45bordcpbKUzPQuNMeta } from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mon-tableau-de-bord.vue?macro=true";
import { default as motdepasse_45oublieLVJQbApt9ZMeta } from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/motdepasse-oublie.vue?macro=true";
import { default as renouveler_45motdepassepup3aF8PgbMeta } from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/renouveler-motdepasse.vue?macro=true";
export default [
  {
    name: "adminasuser",
    path: "/adminasuser",
    meta: adminasuserGzijq1pieyMeta || {},
    component: () => import("/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/adminasuser.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexjU56J6KjWBMeta || {},
    component: () => import("/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/index.vue")
  },
  {
    name: "mes-charges",
    path: "/mes-charges",
    component: () => import("/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mes-charges.vue")
  },
  {
    name: "mes-consommations",
    path: "/mes-consommations",
    component: () => import("/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mes-consommations.vue")
  },
  {
    name: "mes-documents",
    path: "/mes-documents",
    component: () => import("/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mes-documents.vue")
  },
  {
    name: "mes-equipements",
    path: "/mes-equipements",
    component: () => import("/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mes-equipements.vue")
  },
  {
    name: "mon-profil",
    path: "/mon-profil",
    component: () => import("/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mon-profil.vue")
  },
  {
    name: "mon-tableau-de-bord",
    path: "/mon-tableau-de-bord",
    component: () => import("/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mon-tableau-de-bord.vue")
  },
  {
    name: "motdepasse-oublie",
    path: "/motdepasse-oublie",
    meta: motdepasse_45oublieLVJQbApt9ZMeta || {},
    component: () => import("/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/motdepasse-oublie.vue")
  },
  {
    name: "renouveler-motdepasse",
    path: "/renouveler-motdepasse",
    meta: renouveler_45motdepassepup3aF8PgbMeta || {},
    component: () => import("/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/renouveler-motdepasse.vue")
  }
]