import payload_plugin_qLmFnukI99 from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import plugin_nuxt3_gGdllsjxuZ from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/node_modules/nuxt-mail/dist/plugin-nuxt3.js";
import app_startup_config_NB2XiXhnLL from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/plugins/app-startup-config.ts";
import chartjs_1wC9lAM0Tp from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/plugins/chartjs.ts";
import fontawesome_cn2c4tOOHz from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/plugins/fontawesome.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  plugin_nuxt3_gGdllsjxuZ,
  app_startup_config_NB2XiXhnLL,
  chartjs_1wC9lAM0Tp,
  fontawesome_cn2c4tOOHz
]